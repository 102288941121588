<template>
  <!--begin::Basic info-->
  <div class="card mb-5 mb-xl-10">
    <!--begin::Card header-->
    <div class="card-header border-0 bg-default">
      <!--begin::Card title-->
      <div class="card-title m-0 d-flex justify-content-between w-100">
        <h3 class="fw-bolder m-0 text-white">Cari Wilayah</h3>
        <div
          class="
            float-end
            d-flex
            align-items-right
            justify-content-between
            w-50
          "
        >
          <select
            name=""
            id=""
            class="form-select me-2"
            @input="getKecamatan"
            v-model="form.kecamatan"
          >
            <option value="" selected>Pilih Kecamatan</option>
            <option
              v-for="(item, i) in kecamatan"
              :key="i"
              :value="item.territory.id"
            >
              {{ item.territory.name }}
            </option>
          </select>
          <select
            name=""
            id=""
            class="form-select me-2"
            @input="getKelurahan"
            v-model="form.kelurahan"
          >
            <option value="" selected>Pilih Kelurahan</option>
            <template v-if="kelurahan">
              <option
                v-for="(item, i) in kelurahan"
                :key="i"
                :value="item.territory.id"
              >
                {{ item.territory.name }}
              </option>
            </template>
          </select>
          <!-- <select
            name=""
            id=""
            class="form-select me-2 w-50"
            @input="getRw"
            v-model="form.rw"
          >
            <option value="" selected>RW</option>
            <template v-if="rw">
              <option
                v-for="(item, i) in rw"
                :key="i"
                :value="item.territory.id"
              >
                {{ item.territory.name }}
              </option>
            </template>
          </select> -->
        </div>
      </div>
      <!--end::Card title-->
    </div>
    <!--begin::Card header-->

    <!--begin::Content-->
    <div id="kt_account_profile_details" class="collapse show">
      <!--begin::Card body-->
      <div class="card-body border-top p-0">
        <!--begin::Input group-->
        <!-- <div class="row mb-6"> -->
        <div style="height: 64vh; width: 100%">
          <l-map
            v-model="zoom"
            v-model:zoom="zoom"
            :zoomAnimation="true"
            :options="{ zoomControl: false }"
            :center="[-6.304159, 106.724997]"
          >
            <l-tile-layer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              layer-type="base"
              name="OpenStreetMap"
            />
            <l-control-layers />
            <l-control-zoom position="bottomright"></l-control-zoom>

            <template v-for="item in markers" :key="item.cadre.id">
              <!-- icon -->
              <l-marker :lat-lng="[item.cadre.latitude, item.cadre.longitude]">
                <l-icon :icon-url="icon" :icon-size="iconSize" />
                <l-tooltip>
                  <div class="card" style="width: max-content">
                    <div class="card-body p-3">
                      <div
                        class="
                          d-flex
                          align-items-center
                          justify-content-between
                        "
                      >
                        <span class="fs-5 fw-bolder flex-shrink-0">
                          <img
                            :src="item.cadre.profile"
                            class="img-thumbnail rounded"
                            alt=""
                          />
                        </span>
                        <span class="fs-5 fw-bolder">
                          <div>
                            {{ item.cadre.name }}
                          </div>
                          <div>
                            {{ item.cadre.address }}
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </l-tooltip>
              </l-marker>
              <l-polygon
                :lat-lngs="JSON.parse(item.territory.border)"
                color="orange"
              />
            </template>
          </l-map>
        </div>
        <!-- </div> -->
        <!--end::Input group-->
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Content-->
  </div>
  <!--end::Basic info-->
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
// import { useRouter } from "vue-router";
// import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

import {
  LMap,
  LIcon,
  LTileLayer,
  LMarker,
  LControlLayers,
  LControlZoom,
  LTooltip,
  LPolygon,
} from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";

export default defineComponent({
  name: "Kadermaps",
  components: {
    LMap,
    LIcon,
    LTileLayer,
    LMarker,
    LControlLayers,
    LControlZoom,
    LTooltip,
    LPolygon,
  },
  setup() {
    setCurrentPageTitle("Peta Sebaran Kader");

    const store = useStore();
    // const router = useRouter();
    store.dispatch("AllTerritory", { type: 4 });
    // store.dispatch("AllCadreDashboard");

    const center = ref([-6.304159, 106.724997]);

    const zoom = ref(12);

    const iconSize = ref([50, 50]);

    const path = window.location.origin + "/";
    const icon = path + "other/png/vuesax-bold-user-tag.png";

    const markers = computed(() => store.state.CadreDashboardModule.all);
    const kecamatan = computed(() => store.state.TerritoryModule.kecamatan);
    const kelurahan = computed(() => store.state.TerritoryModule.kelurahan);
    const rw = computed(() => store.state.TerritoryModule.rw);

    const form = ref({
      kecamatan: "",
      kelurahan: "",
      rw: "",
    });

    const getKecamatan = (el) => {
      form.value.kelurahan = "";
      if (el.target.value) {
        store.dispatch("AllTerritory", { type: 5, parent_id: el.target.value });
        updateDataWilayah(el.target.value);
      } else {
        updateTanpaWilayah();
        store.commit("SET_ALL_TERRITORY", { type: 5, territories: [] });
      }
    };

    const getKelurahan = (el) => {
      if (el.target.value) {
        // store.dispatch("AllTerritory", { type: 6, parent_id: el.target.value });
        updateDataWilayah(el.target.value);
      } else {
        // store.commit("SET_ALL_TERRITORY", { type: 6, territories: [] });
        updateDataWilayah(form.value.kecamatan);
      }
    };

     const getRw = (el) => {
      form.value.rw = "";
      if (el.target.value) {
        // store.dispatch("AllTerritory", { type: 7, parent_id: el.target.value });
        updateDataWilayah(el.target.value);
      } else {
        updateDataWilayah(form.value.kelurahan);
        // store.commit("SET_ALL_TERRITORY", { type: 7, territories: [] });
      }
    };

    const updateDataWilayah = (wilayah) => {
      store.dispatch("AllCadreDashboard", {
        territory_id: wilayah,
      });
    };
    const updateTanpaWilayah = () => {
      store.dispatch("AllCadreDashboard");
    };

    return {
      center,
      markers,
      icon,
      zoom,
      kecamatan,
      kelurahan,
      rw,
      form,
      iconSize,
      getKecamatan,
      getKelurahan,
      getRw,
    };
  },
});
</script>
